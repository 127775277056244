<template>
  <section>
    <StatusBar v-if="!isCheckoutDesktopFlow" />
    <div :class="{ 'px-4': !isCheckoutDesktopFlow }">
      <h1 class="text-2xl md:text-4xl font-bold font-heading mb-2">Payment</h1>
      <p>All fields are required.</p>

      <div
        v-if="ui.error.code"
        id="error-display"
        class="p-2 bg-red-200 text-red-600 border border-red-400 my-4 rounded"
      >
        <template v-if="ui.error.code == 'table_unavailable'">
          Sorry, table is already reserved. Please select other tables
          <a @click.prevent="selectTables">here</a>.
        </template>

        <template v-else-if="ui.error.code === 'seating_time_full'">
          The seating time you selected is no longer available.
          <router-link :to="{ name: 'setSeatingTime' }" class="underline">
            Please go back to select a new seating time here.
          </router-link>
        </template>

        <template v-else>
          {{ ui.error.message }}
        </template>
      </div>

      <div class="mt-6 flex flex-col">
        <CheckoutForm ref="checkoutForm" />

        <div v-if="!isCheckoutDesktopFlow" class="price-information">
          <CheckoutDetailTable />
          <CheckoutPromoCode class="my-8" />
        </div>

        <CheckoutBottomBar
          :nextButtonText="saveButtonText"
          :isNextButtonDisabled="paying || !checkoutForm?.isFormValid"
          @click:next="onSave"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { mapStores } from 'pinia'
import { defineComponent, useTemplateRef } from 'vue'

import CheckoutBottomBar from '@/modules/guestUi/components/organisms/CheckoutBottomBar.vue'
import CheckoutDetailTable from '@/modules/guestUi/components/organisms/CheckoutDetailTable.vue'
import CheckoutForm from '@/modules/guestUi/components/organisms/CheckoutForm.vue'
import CheckoutPromoCode from '@/modules/guestUi/components/organisms/CheckoutPromoCode.vue'
import StatusBar from '@/modules/guestUi/components/organisms/StatusBar.vue'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { blankError, clearError, displayHandled } from '@/utils/errors'

export default defineComponent({
  name: 'PaymentView',
  components: {
    CheckoutForm,
    CheckoutBottomBar,
    CheckoutPromoCode,
    CheckoutDetailTable,
    StatusBar,
  },
  setup() {
    const checkoutForm = useTemplateRef<InstanceType<typeof CheckoutForm>>('checkoutForm')
    return { checkoutForm }
  },
  data() {
    return {
      paying: false,
      ui: {
        error: blankError(),
      },
    }
  },

  async created() {
    await this.settingStore.loading
  },

  computed: {
    ...mapStores(useSettingStore, useCheckoutStore),

    isCheckoutDesktopFlow(): boolean {
      return this.checkoutStore.isDesktopFlow
    },

    saveButtonText(): string {
      if (this.paying) return 'Processing...'
      return 'Purchase'
    },
  },

  methods: {
    async onBack(): Promise<void> {
      history.go(-1)
    },

    selectTables(): void {
      this.$router.push({ name: 'setTable' }).catch(() => {})
    },

    async onSave(): Promise<void> {
      if (this.paying || !this.checkoutForm) return
      this.paying = true
      clearError(this.ui.error)

      try {
        await this.checkoutForm.submitPayment()
      } catch (error) {
        displayHandled(error, this.ui.error)
      } finally {
        this.paying = false
      }
    },
  },
})
</script>
