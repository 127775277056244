<template>
  <section class="w-full lg:container lg:mx-auto">
    <PerformanceList :performances="performances" />
    <div ref="loader">
      <Spinner class="!h-24" :display="hasMore" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useElementVisibility } from '@vueuse/core'
import moment from 'moment'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onServerPrefetch, ref, watch } from 'vue'

import Spinner from '@/common/components/atoms/Spinner.vue'
import { StorePerformance } from '@/models'
import PerformanceList from '@/modules/guestUi/components/organisms/PerformanceList.vue'
import { usePerformancePaginate } from '@/modules/guestUi/stores/PerformancePaginateStore'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'

const settingStore = useSettingStore()
onServerPrefetch(async () => {
  settingStore.ssrTitle = `Shows - ${settingStore.venue_name}`
  settingStore.ssrDescription = `Purchase tickets for upcoming shows at ${settingStore.venue_name}.`
})

const checkoutStore = useCheckoutStore()
onMounted(async () => {
  await checkoutStore.abandonAnonymousReservation()
  checkoutStore.clearAll()
  scrollToShow()
})
function scrollToShow() {
  if (!checkoutStore.scrollPositionFromSetShow) return
  window.scrollTo({ top: checkoutStore.scrollPositionFromSetShow - 100 })
  checkoutStore.setScrollPositionFromSetShow(null)
}

const performancePaginateStore = usePerformancePaginate()
const { hasMore, isLoading } = storeToRefs(performancePaginateStore)
const performances = computed<StorePerformance[]>(() =>
  performancePaginateStore.performances.filter(p =>
    p.publish_datetime === null ? true : moment(p.publish_datetime).isSameOrBefore(moment()),
  ),
)

const loader = ref<HTMLDivElement | null>(null)
const isLoaderInView = useElementVisibility(loader)
watch(isLoaderInView, performancePaginateStore.intersectionObserver)
watch(isLoading, () => {
  if (!isLoading.value) {
    // At this point, we already fetched the next month of performances,
    // however, we want to await for the loader to be pushed down by the new performances
    // before assuming it's still in view.
    // If after rendering the new performances, the loader is still in view,
    // let's keep fetching the next month of performances.
    setTimeout(() => {
      if (isLoaderInView.value) performancePaginateStore.intersectionObserver(true)
    }, 100)
  }
})
</script>
