<template>
  <div class="w-full xl:w-1/2 ml-auto">
    <form @submit.prevent class="mb-8">
      <div class="flex flex-col space-y-2 xs:flex-row xs:space-y-0">
        <TextInput
          id="fname"
          forInput="fname-input"
          v-model="contact.first_name"
          label="First Name"
          classes="w-full xs:w-1/2 xs:pr-2"
          placeholder="First Name"
        />
        <TextInput
          id="lname"
          forInput="lname-input"
          v-model="contact.last_name"
          label="Last Name"
          classes="w-full xs:w-1/2"
        />
      </div>
      <div class="flex flex-col space-y-2 mt-4 xs:flex-row xs:space-y-0 mb-8">
        <TextInput
          id="email"
          forInput="email-input"
          type="email"
          v-model="contact.email"
          label="Email"
          classes="w-full xs:w-3/5 xs:pr-2"
        />
        <TextInput
          id="tel"
          forInput="tel-input"
          type="tel"
          v-model="contact.phone"
          label="Phone"
          classes="w-full xs:w-2/5"
        />
      </div>
      <div v-if="isStripe" id="payment-element" />
    </form>
    <CheckboxInput
      id="accepts-marketing"
      v-model="contact.accepts_marketing"
      :label="`Keep me informed of upcoming ${settingStore.venue_name} shows`"
      guest
    />
    <p v-show="checkout_text" class="text-sm text-fg-g-primary mt-7">
      {{ checkout_text }}
    </p>
    <div v-if="checkout_confirmation_text" class="mt-7">
      <CheckboxInput
        id="accepts-checkout-confirmation"
        v-model="acceptsCheckoutConfirmation"
        label="I agree to the following policy"
        guest
      />
      <div
        class="text-sm text-fg-g-primary mt-2 whitespace-pre-line"
        id="checkout-confirmation-text"
      >
        {{ checkout_confirmation_text }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Stripe, StripeElements, StripeElementsOptionsMode } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useCssVar } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'

import CheckboxInput from '@/common/components/molecules/CheckboxInput.vue'
import TextInput from '@/common/components/molecules/TextInput.vue'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'

const settingStore = useSettingStore()
const { checkout_confirmation_text, public_key, checkout_text, stripe_account, venue_currency } =
  storeToRefs(settingStore)

const checkoutStore = useCheckoutStore()
const { hasSufficientGuestInfo, contact, client_secret, reservation } = storeToRefs(checkoutStore)

const acceptsCheckoutConfirmation = ref<boolean>(false)

const GUEST_UI_QUERY = '.guest-ui'
const guestUIContainer = ref<HTMLDivElement | null>(null)
onMounted(() => {
  guestUIContainer.value = document.querySelector(GUEST_UI_QUERY)
})
const brandColor = useCssVar('--primary-theme-color')
const textColorOnBrand = useCssVar('--colors-button-label', guestUIContainer)
const inputBgColor = useCssVar('--colors-input', guestUIContainer)
const inputTextColor = useCssVar('--colors-fg-g-primary', guestUIContainer)
const labelTextColor = useCssVar('--colors-fg-disclaimer', guestUIContainer)
const placeholderTextColor = useCssVar('--colors-fg-placeholder', guestUIContainer)
const primaryTextFontFamily = useCssVar('--fontFamily-sans', guestUIContainer)
const primaryTextFontFamilyUrl = useCssVar('--primary-text-font-family-url')
const borderColor = useCssVar('--colors-linear-g-primary', guestUIContainer)
const buttonStyle = { boxShadow: 'none', borderColor: borderColor.value, fontWeight: 'normal' }
const focusBorderColor = useCssVar('--g-fg-primary', null, { initialValue: 'black' })
const focusStyle = {
  boxShadow: `0 0 0 3px ${focusBorderColor.value}`,
  borderColor: 'transparent',
}
const elementsOptions = computed<StripeElementsOptionsMode>(() => {
  return {
    mode: 'payment',
    amount: reservation.value!.stripe_total,
    currency: venue_currency.value,
    fonts: [{ cssSrc: primaryTextFontFamilyUrl.value }],
    appearance: {
      variables: {
        colorPrimary: brandColor.value,
        colorBackground: inputBgColor.value,
        colorText: inputTextColor.value,
        colorTextPlaceholder: placeholderTextColor.value,
        borderRadius: '4px',
        fontFamily: primaryTextFontFamily.value,
      },
      rules: {
        '.Tab': buttonStyle,
        '.Tab:focus': focusStyle,
        '.Tab--selected': {
          backgroundColor: brandColor.value,
          color: textColorOnBrand.value,
          borderColor: brandColor.value,
        },
        '.Tab--selected:focus': focusStyle,
        '.TabIcon--selected': { fill: textColorOnBrand.value },
        '.Input': buttonStyle,
        '.Input:focus': focusStyle,
        '.Label': { color: labelTextColor.value },
      },
    },
  }
})

const stripe = ref<Stripe | null>(null)
const elements = ref<StripeElements | null>(null)
const isStripe = computed<boolean>(() => {
  return checkoutStore.pricing.total > 0.5 && public_key.value !== 'pk_test_xxx'
})
const isFormValid = computed<boolean>(() => {
  if (!isStripe.value) return hasSufficientGuestInfo.value
  return (
    (!checkout_confirmation_text.value || acceptsCheckoutConfirmation.value) &&
    hasSufficientGuestInfo.value
  )
})
async function loadStripeForm() {
  await settingStore.loading
  // We want to avoid attempting to load stripe when we know we are running a test
  if (!isStripe.value) return

  stripe.value = await loadStripe(public_key.value, { stripeAccount: stripe_account.value })
  if (!stripe.value) return

  elements.value = stripe.value.elements(elementsOptions.value)
  if (!elements.value) return
  const paymentElement = elements.value.create('payment')
  paymentElement.mount('#payment-element')
}

onMounted(loadStripeForm)

async function submitPayment() {
  if (!isFormValid.value) return

  if (elements.value) {
    elements.value.submit()
  }
  await checkoutStore.checkout(async () => {
    return await stripe.value?.confirmPayment({
      elements: elements.value!,
      clientSecret: client_secret.value!,
      redirect: 'if_required',
    })
  })
}

defineExpose({ isFormValid, submitPayment })
</script>
