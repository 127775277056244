<template>
  <nav>
    <div class="p-8 bg-body-primary">
      <div class="xs:flex justify-between items-center">
        <div class="xs:flex space-x-4 font-sans text-nav text-fg-g-primary text-xs">
          <router-link :to="{ name: 'legal' }">Legal</router-link>
          <template v-if="isLoggedIn">
            <a href="/accounts/logout">Log Out</a>
          </template>
          <template v-else>
            <a href="/venue/login">Venue Login</a>
          </template>
        </div>
      </div>
    </div>
  </nav>
</template>
<script lang="ts">
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import { useUserStore } from '@/stores/user'

export default defineComponent({
  name: 'FooterComponent',
  computed: {
    ...mapState(useUserStore, ['isLoggedIn']),
  },
})
</script>
