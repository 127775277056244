<template>
  <div
    v-if="isDevelopment && visible"
    class="fixed top-2.5 left-1/2 -translate-x-1/2 flex items-center justify-between bg-red-100 border border-red-200 text-red-800 px-4 py-3 rounded shadow-sm"
  >
    <span>🚧 Local Development</span>
    <button
      class="ml-3 text-red-800 opacity-70 hover:opacity-100 text-xl border-none bg-transparent cursor-pointer"
      @click="close"
    >
      <XMarkIcon class="w-6 h-6" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import XMarkIcon from '@/assets/xMark.svg?component'

const route = useRoute()
const isDevelopment = computed(() => route.query.vite === '1')
const visible = ref<boolean>(true)

function close(): void {
  visible.value = false
}
</script>
