import { guestRoutes } from './guest-routes'
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  createRouter,
  createWebHistory,
} from 'vue-router'

import { usePostHog } from '@/common/composables/usePostHog'
import { useSettingStore } from '@/stores/setting'
import { gtmPageView } from '@/utils/utils'

function beforeEach(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const { capturePageleave } = usePostHog()
  capturePageleave(from)

  if (from.query.vite === '1' && !to.query.vite) {
    return next({
      ...to,
      query: { ...to.query, vite: '1' },
    })
  }

  next()
}

async function afterEach(to: RouteLocationNormalized) {
  const settingStore = useSettingStore()
  const { capturePageview, group } = usePostHog()
  capturePageview(to)
  group({
    slug: settingStore.venue_slug,
    name: settingStore.venue_name,
    id: settingStore.venue_id,
  })
  await gtmPageView(to)
}

export function mkGuestRouter() {
  const router = createRouter({
    routes: guestRoutes,
    history: createWebHistory(),
  })
  router.beforeEach(beforeEach)
  router.afterEach(afterEach)
  return router
}
