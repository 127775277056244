<template>
  <div class="flex flex-col space-y-1" :class="[classes]">
    <LabelReset
      v-if="label"
      :forInput="forInput"
      :initialValue="initialValue"
      :modelValue="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      {{ label }}
    </LabelReset>
    <input
      :name="forInput"
      :id="forInput"
      :aria-label="label"
      :type="type"
      class="font-sans placeholder:text-fg-placeholder rounded border border-linear-subtle guest-ui:border-linear-g-primary p-3 bg-surface-primary text-fg-primary guest-ui:bg-input guest-ui:text-fg-g-primary"
      :placeholder="placeholder"
      :value="modelValue"
      @input.stop.prevent="onChange"
      :class="[inputClasses]"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import LabelReset from '@/common/components/molecules/LabelReset.vue'

export default defineComponent({
  name: 'TextInput',
  components: { LabelReset },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    forInput: {
      type: String,
      required: false,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    initialValue: {
      required: false,
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    classes: {
      type: String,
      required: false,
    },
    inputClasses: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  emits: {
    'update:modelValue'(value: string) {
      return typeof value == 'string'
    },
  },
  methods: {
    onChange(event) {
      this.$emit('update:modelValue', event.target.value)
    },
  },
})
</script>
