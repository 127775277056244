// window.location things are weirdly difficult to mock in Jest/jsdom, so we use this
// abstraction to make it easier to mock. It is called loc instead of location to avoid
// confusion with the built-in location object which is available in window/global scope.

export default {
  redirect: (url: string) => {
    window.location.href = url
  },

  reload: () => {
    window.location.reload()
  },

  location: () => {
    return window.location
  },
}
