<template>
  <div class="relative w-full h-full" ref="roomMapContainer">
    <svg
      class="overflow-visible"
      width="100%"
      height="100%"
      :viewBox="`0 0 ${SVG_RELATIVE_WIDTH} ${svgRelativeHeight}`"
      preserveAspectRatio="xMidYMin meet"
      ref="svgElementRef"
      role="radiogroup"
    >
      <RoomGrid
        v-if="displayGrid"
        :columnCount="SVG_RELATIVE_WIDTH"
        :svgHeight="svgRelativeHeight"
        :ratio="svgScale"
      />

      <!-- Render this doing our own math to force Safari to render it properly  -->
      <image
        ref="venueBackgroundImage"
        id="room-background-image"
        v-if="backgroundImagePath"
        :href="backgroundImagePath"
        x="0"
        y="0"
        :width="svgDOMWidth"
        :height="svgDOMHeight"
        :transform="`scale(${1 / svgScale})`"
      />

      <g id="room-table-group">
        <slot />
      </g>
    </svg>
  </div>
</template>

<script lang="ts" setup>
import { useColorMode, useElementBounding, useResizeObserver } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, onMounted, provide, readonly, ref, toRefs } from 'vue'

import RoomGrid from '@/common/components/atoms/RoomGrid.vue'
import { ROOM_MAP_BG_MODE, RoomMapBgMode } from '@/common/interfaces/RoomMap'
import { RovingTabindex } from '@/common/utils/RovingTabindex'
import { useSettingStore } from '@/stores/setting'
import { ROOM_TABLE_RELATIVE_WIDTH } from '@/utils/utils'

const props = withDefaults(
  defineProps<{
    displayGrid?: boolean
    backgroundMode?: RoomMapBgMode
  }>(),
  {
    displayGrid: false,
    backgroundMode: ROOM_MAP_BG_MODE.USER_CHOICE,
  },
)
const { displayGrid, backgroundMode } = toRefs(props)

const { ratio: aspectRatio, background_path, dark_background_path } = storeToRefs(useSettingStore())

const colorMode = useColorMode()
const backgroundImagePath = computed<string>(() => {
  if (backgroundMode.value === ROOM_MAP_BG_MODE.USER_CHOICE) {
    return colorMode.value === 'dark' ? dark_background_path.value : background_path.value
  } else if (backgroundMode.value === ROOM_MAP_BG_MODE.FORCE_DARK) {
    return dark_background_path.value
  } else {
    return background_path.value
  }
})

const svgElementRef = ref<SVGElement | null>(null)
onMounted(() => {
  if (!svgElementRef.value) return
  new RovingTabindex(svgElementRef.value as Element)
})
provide('svgElementRef', readonly(svgElementRef))
const svgDOMWidth = ref<number>(500)
const svgDOMHeight = computed(() => svgDOMWidth.value / aspectRatio.value)
const SVG_RELATIVE_WIDTH = ROOM_TABLE_RELATIVE_WIDTH
const svgRelativeHeight = computed(() => SVG_RELATIVE_WIDTH / aspectRatio.value)
const svgScale = computed(() => svgDOMWidth.value / SVG_RELATIVE_WIDTH)

// The background image represents the actual size of the venue room
// The svgDOMWidth and svgDOMHeight values don't represent the size of the venue room
// because the SVG is resized to fit its parent container
// as a result, the venue image is scaled down to fit centered within the SVG
const venueBackgroundImage = ref<HTMLImageElement | null>(null)
const { width: venueDOMWidth } = useElementBounding(venueBackgroundImage)
// Padding in X axis from the border of the SVG to the border of the venue room
const paddingX = computed(() => (svgDOMWidth.value - venueDOMWidth.value) / 2)
provide('paddingX', readonly(paddingX))
const venueScale = computed<number>(() => venueDOMWidth.value / ROOM_TABLE_RELATIVE_WIDTH)
provide('venueScale', readonly(venueScale))

const roomMapContainer = ref<HTMLDivElement | null>(null)
useResizeObserver(roomMapContainer, ([entry]) => {
  svgDOMWidth.value = entry.contentRect.width
})
</script>
