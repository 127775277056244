<template>
  <section ref="calendar" class="relative pb-64 pt-14">
    <Spinner :display="loading" />
    <CalendarMonth
      v-for="month in months"
      :key="`${month.month}-${month.year}`"
      :month="month.month"
      :year="month.year"
      :selectedDate="selectedDate"
      :performances="performancePaginateStore.performances"
      @click:date="selectedDate = $event"
      omitWeeksBeforeToday
    />
  </section>
</template>
<script lang="ts">
import { last, uniq } from 'lodash-es'
import moment from 'moment'
import { mapStores } from 'pinia'
import { defineComponent } from 'vue'

import Spinner from '@/common/components/atoms/Spinner.vue'
import CalendarMonth from '@/components/calendar/CalendarMonth.vue'
import { usePerformancePaginate } from '@/modules/guestUi/stores/PerformancePaginateStore'
import { useSettingStore } from '@/stores/setting'

export default defineComponent({
  name: 'CalendarView',
  components: { Spinner, CalendarMonth },
  data() {
    return {
      selectedDate: moment().format('YYYY-MM-DD'),
      loading: !import.meta.env.SSR,
    }
  },

  serverPrefetch() {
    this.settingStore.ssrTitle = `Calendar - ${this.settingStore.venue_name}`
  },

  async mounted() {
    await this.performancePaginateStore.getPerformances({
      booking: 'true',
      pagination: 'false',
    })
    this.performancePaginateStore.performances = this.performancePaginateStore.performances.filter(
      p => p.publish_datetime === undefined || moment(p.publish_datetime).isSameOrBefore(moment()),
    )
    this.loading = false
  },

  computed: {
    ...mapStores(useSettingStore, usePerformancePaginate),

    currentDetailsComponent() {
      return 'CalendarShowDetails'
    },

    months(): { month: number; year: number }[] {
      // generate all the month+year combinations from today until performancePaginateStore.maxPerformanceDate
      const months: any[] = []
      const maxDate = moment(
        last(uniq(this.performancePaginateStore.performances.map(p => p.date)).sort()) ||
          '1800-01-01',
      )
      let month = moment().startOf('month')
      while (month.isSameOrBefore(maxDate)) {
        months.push({
          month: month.month() + 1,
          year: month.year(),
        })
        month = month.add(1, 'month')
      }
      return months
    },
  },
})
</script>
