<template>
  <div
    v-show="displayBar"
    class="xs:flex flex-col p-2 font-sans font-medium text-base md:px-2 md:py-4 md:text-lg"
    :class="isTableSelection ? 'mb-0' : 'mb-16'"
  >
    <div id="status-bar-show-name" class="mx-2">
      {{ showName }}
    </div>

    <template v-if="performanceDate">
      <div id="status-bar-performance-date-time" class="mx-2 text-xs">
        {{ performanceDate.dateStr }}, {{ performanceDate.timeStr }} Show Time<span
          v-if="guestCount"
          >,
        </span>
        <span v-if="guestCount">{{ guestCount }} Guest<span v-if="guestCount > 1">s</span></span>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { mapState, mapStores } from 'pinia'
import { defineComponent } from 'vue'

import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'

export default defineComponent({
  props: {
    isTableSelection: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    ...mapStores(useSettingStore, useCheckoutStore),
    ...mapState(useCheckoutStore, ['performance']),

    displayBar() {
      return (
        this.performance &&
        this.performance.show &&
        !this.$route.fullPath.includes('/order-details')
      )
    },

    performanceDate() {
      if (this.performance) {
        return {
          dateStr: this.settingStore.longDate(this.performance.datetime),
          timeStr: this.settingStore.timeStr(this.performance.datetime),
        }
      }

      return null
    },

    guestCount() {
      return this.checkoutStore.number_of_guests
    },

    showName() {
      return this.performance?.show.name
    },
  },
})
</script>
