<template>
  <div
    v-if="ui.active"
    class="show-details bg-calendar-bg-active text-calendar-fg-active border border-linear-strong guest-ui:border-linear-g-primary xs:rounded-tl xs:rounded-tr md:border-t-0 md:rounded-tl-none md:rounded-tr-none"
    :class="showId < 0 ? 'no-show-today min-h-[100px]' : 'min-h-[300px]'"
  >
    <Spinner :display="ui.loading" />
    <template v-if="showId < 0">
      <div class="p-4 md:p-8">
        <div class="flex flex-col">
          <h3 class="text-2xl font-semibold font-heading">No Shows Today</h3>
          <p class="text-fg-g-primary text-base font-sans">Please select a different date.</p>
        </div>
      </div>
    </template>
    <template v-else-if="show && !ui.loading">
      <div class="p-4 md:p-8 text-calendar-fg-primary">
        <div class="flex">
          <div class="max-w-full w-64 h-64 mr-4 rounded-md overflow-hidden">
            <picture class="w-full h-full inline-block">
              <source :srcset="show.srcset.sqLg.src" type="image/webp" media="(min-width: 800px)" />
              <source :srcset="show.srcset.sqSm.src" type="image/webp" media="(max-width: 799px)" />
              <img
                :alt="show.name"
                :src="show.srcset.original.src"
                class="w-full h-full object-cover"
              />
            </picture>
          </div>
          <div class="flex flex-col w-3/4 shrink">
            <div class="text-2xl font-semibold font-heading">{{ show.name }}</div>
            <div class="uppercase text-base font-semibold font-date text-fg-g-primary">
              {{ dateStr }}
            </div>
            <div class="mb-2 my-5 text-base hidden whitespace-pre-line font-sans md:block">
              {{ show.description }}
            </div>
            <div class="flex-wrap hidden md:flex md:space-x-4 mt-2">
              <PerformanceButton
                v-for="performance in performancesByShow"
                :key="performance.id"
                :performance="performance"
                :error="ui.error"
              />
            </div>
          </div>
        </div>
        <div class="my-2 text-sm whitespace-pre-line md:hidden">{{ show.description }}</div>
        <div class="flex flex-col flex-wrap space-y-2 mt-4 md:hidden">
          <PerformanceButton
            v-for="performance in performancesByShow"
            :key="performance.id"
            :performance="performance"
            :error="ui.error"
          />
        </div>
        <div v-if="ui.error.message">
          {{ ui.error.message }}
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import moment from 'moment'
import { mapStores } from 'pinia'
import { defineComponent } from 'vue'

import Spinner from '@/common/components/atoms/Spinner.vue'
import { StorePerformance, StoreShow } from '@/models'
import PerformanceButton from '@/modules/guestUi/components/organisms/PerformanceButton.vue'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { blankError } from '@/utils/errors'
import { sortBy } from '@/utils/utils'

export default defineComponent({
  name: 'CalendarShowDetails',
  components: { Spinner, PerformanceButton },
  data() {
    return {
      show: null as null | StoreShow,
      ui: {
        error: blankError(),
        active: true,
        loading: !import.meta.env.SSR,
      },
    }
  },
  props: {
    showId: {
      type: Number,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    performances: {
      type: Array as () => StorePerformance[],
      required: true,
    },
  },

  async mounted() {
    await this.loadShow()
  },

  watch: {
    async showId() {
      await this.loadShow()
    },
  },

  computed: {
    ...mapStores(useSettingStore, useCheckoutStore),

    performancesByShow(): StorePerformance[] {
      return sortBy(
        this.performances.filter(
          performance =>
            performance.show_id == this.showId &&
            moment(performance.date).format('yyyy-MM-DD') ===
              moment(this.date).format('yyyy-MM-DD'),
        ),
        'datetime',
      )
    },
    dateStr(): string {
      return this.settingStore.mediumDate(this.date)
    },
  },

  methods: {
    async loadShow() {
      this.show = null
      this.ui.loading = true

      if (this.showId > 0 && this.performancesByShow.length) {
        this.show = this.performancesByShow[0].show
      }
      this.ui.loading = false
    },
  },
})
</script>
