<template>
  <div class="container px-4 py-12 md:py-16 mx-auto flex flex-col items-start justify-start gap-2">
    <h1 class="text-4xl font-semibold font-heading">Page Not Found</h1>
    <p>The page you're looking for doesn't exist</p>
    <router-link
      to="/"
      class="py-2 px-5 rounded font-semibold text-sm font-button bg-button-guest-primary text-button-label hover:opacity-80 uppercase"
    >
      Return to Home
    </router-link>
  </div>
</template>
