import { cloneDeep } from 'lodash-es'
import moment from 'moment'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import * as api from '@/api/api'
import { StorePerformance, parsePerformance } from '@/models'
import { sortBy, upsertById } from '@/utils/utils'

import { PerformanceId, PerformanceListRead, PerformanceQueryParams } from '@generated/types'

export const usePerformancePaginate = defineStore('performancePaginate', () => {
  const performances = ref<StorePerformance[]>([])
  const isLoading = ref<boolean>(false)
  const hasMore = ref<boolean>(true)

  if (typeof window === 'object' && window?.__tt_preload?.pagination) {
    performances.value = window.__tt_preload.pagination.performances
    hasMore.value = window.__tt_preload.pagination.hasMore
  }

  function storePerformances(performanceList: PerformanceListRead[]) {
    for (const performance of performanceList) {
      upsertById(parsePerformance(performance), performances.value)
    }
  }

  async function getPerformanceWithInvite(performanceId: PerformanceId, inviteCode: string) {
    const response = await api.getPerformance(performanceId, { invite_code: inviteCode })
    storePerformances([response.data])
  }

  async function getPerformances(query: PerformanceQueryParams) {
    const response = await api.getPerformances(query || {})
    storePerformances(response.data.results)
  }

  const maxPerformanceDate = computed<string | undefined>(
    () => sortBy(cloneDeep(performances.value), 'date').pop()?.date,
  )
  async function initializePagination() {
    const endDate = moment().add(30, 'days').format('YYYY-MM-DD')

    const response = await api.getPerformances({
      booking: 'true',
      pagination: 'true',
      end_date: endDate,
    })

    // If no performances found in next 30 days, fetch all performances
    if (response.data.results.length === 0) {
      await getPerformances({ booking: 'true', pagination: 'false' })
      hasMore.value = false
    } else storePerformances(response.data.results)
  }

  async function loadNextMonthOfPerformances() {
    if (isLoading.value) return
    isLoading.value = true

    const startDate = maxPerformanceDate.value || moment().format('YYYY-MM-DD')
    const endDate = moment(startDate).add(30, 'days').format('YYYY-MM-DD')
    const response = await api.getPerformances({
      booking: 'true',
      pagination: 'true',
      start_date: startDate,
      end_date: endDate,
    })
    const performanceLengthBeforeUpdate = performances.value.length
    storePerformances(response.data.results)
    const newPerformanceLength = performances.value.length
    if (performanceLengthBeforeUpdate === newPerformanceLength) {
      // If no new performances in next 30 days, fetch all remaining performances
      await getPerformances({ booking: 'true', pagination: 'false' })
      hasMore.value = false
    } else {
      hasMore.value = true
    }
    isLoading.value = false
  }
  async function intersectionObserver(isVisible: boolean) {
    if (isVisible && hasMore.value && !isLoading.value) await loadNextMonthOfPerformances()
  }

  return {
    performances,
    getPerformances,
    getPerformanceWithInvite,
    initializePagination,
    intersectionObserver,
    hasMore,
    isLoading,
  }
})
