<template>
  <div class="w-full flex flex-col">
    <ShowCard
      v-for="show in shows"
      :key="`show-${show.id}-${show.date}`"
      :id="`show-${show.id}-${show.date}`"
      class="show-card border-b last:border-b-0 border-linear-g-primary py-12 px-4 md:px-0 md:py-16 md:gap-10"
      :show="show"
      :ui="ui"
      @performance:click="saveScrollPosition"
      @showLink:click="onShowDetailLinkClick"
      @readMore:click="onReadMore"
    />
  </div>
  <div
    v-if="readMoreDialog && currentShow"
    @keyup.esc="readMoreDialogClose"
    ref="readMoreDialogContainer"
    tabindex="0"
    class="read-more-dialog fixed top-0 left-0 w-full h-full flex items-center justify-center md:p-16 lg:p-32"
  >
    <!--Background overlay-->
    <div class="bg-black/80 absolute left-0 top-0 w-full h-full" @click="readMoreDialogClose" />

    <!--Dialog-->
    <div
      class="relative w-full max-h-full overflow-y-auto bg-body-primary z-10 lg:container lg:mx-auto"
    >
      <!--Close button-->
      <button
        :id="`read-more-dialog-close-${currentShow.id}-${currentShow.date}`"
        class="w-10 h-10 rounded flex items-center justify-center top-0 bg-body-primary absolute right-0 z-20"
        @click="readMoreDialogClose"
        aria-label="Close Show Description"
      >
        <xCloseGuest />
      </button>

      <!--Show card-->
      <ShowCard
        class="p-4 md:gap-10 md:p-8"
        :show="currentShow"
        :id="`show-${currentShow.id}-${currentShow.date}-in-dialog`"
        :ui="ui"
        descriptionDisplayType="long"
        keepReadMoreText
        @performance:click="saveScrollPosition"
        @readMore:click="readMoreDialogClose"
      >
        <template #readMoreText>Read Less</template>
      </ShowCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cloneDeep } from 'lodash-es'
import { computed, ref, toRefs, watch } from 'vue'

import xCloseGuest from '@/assets/xCloseGuest.svg?component'
import { StorePerformance } from '@/models'
import ShowCard from '@/modules/guestUi/components/organisms/ShowCard.vue'
import { DisplayPerformance, DisplayShow } from '@/modules/guestUi/interfaces/shows'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { blankError } from '@/utils/errors'
import { sortBy } from '@/utils/utils'

import { PerformanceId } from '@generated/types'

const props = withDefaults(
  defineProps<{
    performances: StorePerformance[]
    idsToForcePurchase?: PerformanceId[]
  }>(),
  { idsToForcePurchase: () => [] },
)
const { performances, idsToForcePurchase } = toRefs(props)

const readMoreDialog = ref(false)
const readMoreDialogContainer = ref<HTMLDivElement | null>(null)
const currentShow = ref<DisplayShow | null>(null)
const ui = ref({ error: blankError() })

watch(readMoreDialog, value => {
  if (value) {
    document.body.style.setProperty('overflow', 'hidden')
    setTimeout(() => {
      readMoreDialogContainer.value?.focus()
    }, 0)
  } else {
    document.body.style.setProperty('overflow', 'initial')
  }
})

function onReadMore(show: DisplayShow) {
  readMoreDialog.value = true
  currentShow.value = show
  saveScrollPosition(show.performances[0])
}

function readMoreDialogClose() {
  // Focus on the current show card, accessibility
  const currentShowCard = document
    .getElementById(`show-${currentShow.value?.id}-${currentShow.value?.date}`)!
    .querySelector('.performance-btn') as HTMLButtonElement
  currentShowCard?.focus()

  readMoreDialog.value = false
  currentShow.value = null
}

const checkoutStore = useCheckoutStore()
const onShowDetailLinkClick = (show: DisplayShow) => {
  document.body.style.setProperty('overflow', 'initial')
  const performance = show.performances[0]
  saveScrollPosition(performance)
}
function saveScrollPosition(performance: StorePerformance) {
  document.body.style.setProperty('overflow', 'initial')
  const performanceContainerElement = document
    .getElementById(`performance-${performance.id}`)
    ?.closest('.performances')
  if (!performanceContainerElement) return
  const { top } = performanceContainerElement.getBoundingClientRect()
  const topFromWindow = top + window.scrollY
  checkoutStore.setScrollPositionFromSetShow(topFromWindow)
}
const settingStore = useSettingStore()
const shows = computed<DisplayShow[]>(() => {
  const shows: DisplayShow[] = []

  for (const performance of performances.value) {
    if (!checkoutStore.isPerformanceVisibleOnTheList(performance)) continue

    const show = shows.find(show => show.date == performance.date && show.id == performance.show_id)
    const displayPerformance = cloneDeep(performance) as DisplayPerformance
    displayPerformance.forcePurchase = idsToForcePurchase.value.includes(performance.id)

    if (show) {
      show.performances.push(displayPerformance)
      sortBy(show.performances, 'datetime')
    } else {
      shows.push({
        ...displayPerformance.show,
        id: displayPerformance.show_id,
        date: displayPerformance.date,
        datetime: displayPerformance.datetime,
        dateStr: settingStore.mediumDate(displayPerformance.datetime),
        description: displayPerformance.show?.description || '',
        position: displayPerformance.show?.position || [],
        performances: [displayPerformance],
      })
    }
  }

  return sortBy(shows, 'datetime')
})
</script>
