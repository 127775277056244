import * as Sentry from '@sentry/vue'
import { defineStore } from 'pinia'

import * as api from '@/api/api'
import { byId, readCookie } from '@/utils/utils'

import { UserGroup, UserGroupType, UserId, UserRead } from '@generated/types'

export interface StoreUser {
  id: number
  email?: string
  first_name?: string
  last_name?: string
  name: string
  phone?: string | null
  groups: UserGroupType[]
}

export interface UserState {
  loading: Promise<boolean>
  users: StoreUser[]
  me: StoreUser & UserRead
}

export function parseUser(user: UserRead): StoreUser {
  return {
    ...user,
    groups: user.groups.slice().sort(),
    name: user.first_name + ' ' + user.last_name,
  }
}

export const useUserStore = defineStore({
  id: 'user',
  state: (): UserState => {
    const state: UserState = {
      loading: Promise.resolve(false),
      users: [],
      me: {
        id: 0,
        email: '',
        first_name: '',
        last_name: '',
        name: '',
        phone: '',
        groups: [],
        accepts_marketing: false,
        venues: [],
        hubspot_token: '',
      },
    }
    if (readCookie('tt_user')) {
      state.loading = api
        .getMe()
        .then(response => {
          const store = useUserStore()
          store.$patch({ me: parseUser(response.data) })
          if (import.meta.env.VUE_APP_VERSION) {
            Sentry.setUser({
              id: store.me.id + '',
              email: store.me.email,
              username: store.me.email,
            })
            if (window.dataLayer) {
              window.dataLayer.push({
                user_id: store.me.id,
              })
            }
            if (window.hsConversationsSettings) {
              window.hsConversationsSettings.identificationEmail = store.me.email
              window.hsConversationsSettings.identificationToken = store.me.hubspot_token
              if (window.HubSpotConversations && window.HubSpotConversations.widget) {
                window.HubSpotConversations.widget.load()
              } else {
                window.hsConversationsSettings.loadImmediately = true
              }
            }
          }
          return true
        })
        .catch(reason => {
          Sentry.captureException(reason)
          return false
        })
    }
    return state
  },
  getters: {
    cookieUser(): string | undefined {
      return readCookie('tt_user')
    },
    isLoggedIn(): boolean {
      return !!this.cookieUser
    },
    isOwner(): boolean {
      return this.me.groups.includes(UserGroup.OWNERS)
    },
    hasGroup() {
      return (group: UserGroupType) => {
        return useUserStore().me.groups.includes(group)
      }
    },
    isSuperuser(): boolean {
      return this.me.email.endsWith('@turntabletickets.com')
    },
    initials(): string {
      return (this.me.first_name.charAt(0) || '') + (this.me.last_name.charAt(0) || '')
    },
    canRefund(): boolean {
      return this.me.groups.includes('refunders')
    },
    isFromVenueStaff(): boolean {
      return this.me.email.endsWith('turntabletickets.com')
    },
  },
  actions: {
    userById(userId: UserId) {
      return this.users.find(byId(userId))
    },
    async fetchUsers(): Promise<StoreUser[]> {
      const response = await api.getUsers()
      this.users = response.data.map(parseUser)
      return this.users
    },
    getUserById(id: number): StoreUser | undefined {
      return this.users.find(byId(id))
    },
    async sendResetPasswordEmail(userId: UserId) {
      await api.sendResetPasswordEmail(userId)
    },
    async login(email: string, password: string): Promise<void> {
      const { data: user } = await api.login(email, password)
      this.me = parseUser(user)
    },
  },
})
