<template>
  <section class="w-full lg:container lg:mx-auto" ref="showList">
    <PerformanceList :performances="selectedPerformance" :idsToForcePurchase="[performanceId]" />
  </section>
</template>

<script lang="ts" setup>
import { computed, onMounted, onServerPrefetch, toRefs } from 'vue'
import { useRoute } from 'vue-router'

import { StorePerformance } from '@/models'
import PerformanceList from '@/modules/guestUi/components/organisms/PerformanceList.vue'
import { usePerformancePaginate } from '@/modules/guestUi/stores/PerformancePaginateStore'
import { useCheckoutStore } from '@/stores/checkout'

const props = withDefaults(defineProps<{ performanceId?: number }>(), {
  performanceId: undefined,
})
const { performanceId } = toRefs(props)

const checkoutStore = useCheckoutStore()
const performancePaginateStore = usePerformancePaginate()
const route = useRoute()
const inviteCode = computed<string | undefined>(() => route.query.invite as string | undefined)

onMounted(async () => {
  await checkoutStore.abandonAnonymousReservation()
  checkoutStore.clearAll()
  checkoutStore.setInviteCode(inviteCode.value)
})

onServerPrefetch(fetchPerformance)
async function fetchPerformance() {
  if (!performanceId.value || !inviteCode.value) return
  await performancePaginateStore.getPerformanceWithInvite(performanceId.value, inviteCode.value)
}

const selectedPerformance = computed<StorePerformance[]>(() => {
  const performance = performancePaginateStore.performances.find(
    performance => performance.id === performanceId.value,
  )
  return performance ? [performance] : []
})
</script>
