import { RouteRecordRaw } from 'vue-router'

import CheckoutView from '@/modules/guestUi/pages/CheckoutView.vue'
import NotFound from '@/modules/guestUi/pages/NotFound.vue'
import OrderDetails from '@/modules/guestUi/pages/OrderDetails.vue'
import PaymentView from '@/modules/guestUi/pages/PaymentView.vue'
import SetAddOns from '@/modules/guestUi/pages/SetAddOns.vue'
import SetGuests from '@/modules/guestUi/pages/SetGuests.vue'
import SetSeatingTime from '@/modules/guestUi/pages/SetSeatingTime.vue'
import SetTables2 from '@/modules/guestUi/pages/SetTables2.vue'
import { ssrRoutes } from '@/router/ssr-routes'
import { useCheckoutStore } from '@/stores/checkout'

export const guestRoutes: RouteRecordRaw[] = [
  ...ssrRoutes,
  {
    path: '/guests',
    name: 'setGuests',
    component: SetGuests,
    meta: {
      guest: true,
      pageTitle: 'Guests',
    },
    beforeEnter: (to, from, next) => {
      return useCheckoutStore().isSetGuestsReady ? next() : next({ name: 'setShow' })
    },
  },
  {
    path: '/tables',
    name: 'setTable',
    component: SetTables2,
    meta: {
      guest: true,
      pageTitle: 'Tables',
    },
    beforeEnter: (to, from, next) => {
      return useCheckoutStore().isSetTablesReady ? next() : next({ name: 'setGuests' })
    },
  },
  {
    path: '/seating-time',
    name: 'setSeatingTime',
    component: SetSeatingTime,
    meta: {
      guest: true,
      pageTitle: 'Seating Time',
    },
    beforeEnter: (to, from, next) => {
      return useCheckoutStore().isSetSeatingTimeReady ? next() : next({ name: 'setTable' })
    },
  },
  {
    path: '/addons',
    name: 'setAddOns',
    // @ts-ignore
    component: SetAddOns,
    meta: {
      guest: true,
      pageTitle: 'Add-ons',
    },
    beforeEnter: (to, from, next) => {
      return useCheckoutStore().isAddOnsReady ? next() : next({ name: 'setSeatingTime' })
    },
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckoutView,
    meta: {
      guest: true,
      pageTitle: 'Checkout',
    },
    beforeEnter: (to, from, next) => {
      return useCheckoutStore().isCheckoutReady ? next() : next({ name: 'setSeatingTime' })
    },
  },
  {
    path: '/payment',
    name: 'payment',
    component: PaymentView,
    meta: {
      guest: true,
      pageTitle: 'Checkout',
    },
    beforeEnter: (to, from, next) => {
      return useCheckoutStore().isCheckoutReady ? next() : next({ name: 'setSeatingTime' })
    },
  },
  {
    path: '/order-details',
    name: 'order-details',
    component: OrderDetails,
    meta: {
      guest: true,
      pageTitle: 'Order Complete',
    },
    beforeEnter: (to, from, next) => {
      const checkoutStore = useCheckoutStore()
      return checkoutStore.isDetailsReady ? next() : next(checkoutStore.nextRoute)
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
    meta: {
      guest: true,
      pageTitle: 'Page Not Found',
    },
  },
]
