<template>
  <div
    id="checkout-desktop-sidebar"
    class="bg-body-checkout-sidebar w-[485px] p-12 min-h-screen overflow-auto max-h-screen sticky top-0"
  >
    <div class="flex justify-start items-start">
      <div class="rounded-md w-20 h-20 border border-linear-g-primary overflow-hidden mr-4">
        <img :src="show.image" :alt="show.name" class="w-full h-full object-cover" />
      </div>
      <div class="shrink-[20] inline-flex flex-col">
        <h2 id="checkout-sidebar-show-name" class="font-heading font-semibold text-lg inline">
          {{ show.name }}
        </h2>
        <span id="checkout-sidebar-performance-date" class="font-sans inline">
          {{ performanceDate }}
        </span>
        <span id="checkout-sidebar-showtime" class="inline-block">{{ showtime }}</span>
      </div>
    </div>

    <div v-if="isSetTablesPage || isSetSeatingTimesPage || isSetAddOnsPage" class="mt-4">
      <span id="checkout-sidebar-total-guests">{{ totalGuests }} guests</span>
      <TableListViewSelected
        v-show="!!selectedTablesId.length"
        uid="checkout-desktop-selected-table-header"
        :tables="seatedTables"
        :totalGuests="totalGuests"
        :selectable="seatedGuests !== totalGuests"
        invertColors
      />
    </div>

    <div v-if="isCheckoutPage" class="pt-5 mb-10">
      <div>{{ totalGuests }} guests</div>
      <div class="font-sans mb-5" id="checkout-sidebar-seating-time">{{ seatingTime }}</div>
      <CheckoutPromoCode class="mb-5 mt-3" />
      <CheckoutDetailTable id="checkout-detail-table" />
    </div>

    <div v-if="isSetSeatingTimesPage || isSetAddOnsPage || isCheckoutPage" class="mt-5">
      <RoomMap id="room-map" :backgroundMode="roomMapBgMode">
        <RoomTable
          v-for="table in tables"
          :key="table.id"
          :height="table.h"
          :width="table.w"
          :rotation="table.rotation"
          :shape="table.shape"
          :x="table.x"
          :y="table.y"
          :marker="selectedTablesId.includes(table.id) ? 'checkmark' : 'none'"
          :fillClass="selectedTablesId.includes(table.id) ? 'fill-primary' : 'fill-table-available'"
        />
      </RoomMap>
    </div>
  </div>
</template>

<script lang="ts">
import { mapStores } from 'pinia'
import { defineComponent } from 'vue'

import RoomTable from '@/common/components/molecules/RoomTable.vue'
import RoomMap from '@/common/components/organisms/RoomMap.vue'
import { ROOM_MAP_BG_MODE, RoomMapBgMode } from '@/common/interfaces/RoomMap'
import { StorePerformance } from '@/models'
import TableListViewSelected from '@/modules/guestUi/components/molecules/TableListViewSelected.vue'
import CheckoutDetailTable from '@/modules/guestUi/components/organisms/CheckoutDetailTable.vue'
import CheckoutPromoCode from '@/modules/guestUi/components/organisms/CheckoutPromoCode.vue'
import { EnrichedTable, tablesForSeatedParty } from '@/orders'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { sum } from '@/utils/utils'

import { PerformanceTableRead } from '@generated/types'

export default defineComponent({
  name: 'CheckoutDesktopDetailSidebar',
  components: {
    RoomTable,
    RoomMap,
    CheckoutPromoCode,
    CheckoutDetailTable,
    TableListViewSelected,
  },
  data() {
    return {
      roomMapBgMode:
        getComputedStyle(document.documentElement).getPropertyValue('--mode') ||
        (ROOM_MAP_BG_MODE.FORCE_LIGHT as RoomMapBgMode),
    }
  },
  computed: {
    ...mapStores(useCheckoutStore, useSettingStore),
    performance(): StorePerformance {
      return this.checkoutStore.performance as StorePerformance
    },
    performanceDate(): string {
      return `${this.settingStore.fullDate(this.performance.date)}`
    },
    showtime(): string {
      return `${this.settingStore.timeStr(this.performance.datetime)} Showtime`
    },
    seatingTime(): string {
      return (
        this.settingStore.timeStr(this.checkoutStore.seating_time?.datetime || '') + ' Seating Time'
      )
    },
    show(): StorePerformance['show'] {
      return this.performance.show
    },
    totalGuests(): number {
      return this.checkoutStore.number_of_guests
    },
    seatedTables(): EnrichedTable[] {
      return tablesForSeatedParty(this.performance, this.checkoutStore.tables, this.totalGuests)
    },
    seatedGuests(): number {
      return sum(this.seatedTables.map(table => table.guests))
    },
    selectedTablesId(): number[] {
      return this.checkoutStore.tables
    },
    tables(): PerformanceTableRead[] {
      return (
        this.performance.tables?.map(table => ({
          ...table,
          available: true,
        })) || []
      )
    },
    isSetTablesPage(): boolean {
      return this.$route.name === 'setTable'
    },
    isSetSeatingTimesPage(): boolean {
      return this.$route.name === 'setSeatingTime'
    },
    isSetAddOnsPage(): boolean {
      return this.$route.name === 'setAddOns'
    },
    isCheckoutPage(): boolean {
      return this.$route.name === 'checkout' || this.$route.name === 'payment'
    },
  },
})
</script>
